import React, { useState, useEffect } from "react";
import { useWindowSize } from "../../utils/useWindowSize.js";
import { workExperiences } from "../Experience/Experience.js"; // Import workExperiences array

const calculateExperience = () => {
    let totalMonths = 0;
    workExperiences.forEach(work => {
        if(work.position.includes("intern") || work.position.includes("Intern")) {
            return;
        } else {
            let startDate = work.startDate; 
            let endDate = work.endDate;
            if (endDate === "Present") {
                endDate = new Date();
            }
            const start = new Date(startDate);
            const end = new Date(endDate);
            const months = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth() + 1);
            totalMonths += months;
        }
    });

    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    return { years, months };
};

const TypingText = ({ text }) => {
    const [displayedText, setDisplayedText] = useState(text);
    const { height } = useWindowSize();

    useEffect(() => {
        let currentIndex = 0;

        const typingInterval = setInterval(() => {
            if (currentIndex <= text.length) {
                setDisplayedText(text.slice(0, currentIndex));
                currentIndex += 1;
            } else {
                clearInterval(typingInterval);
            }
        }, 20);

        return () => {
            clearInterval(typingInterval);
        };
    }, [text]);

    return (
        <p
            style={{
                textAlign: "center",
                marginTop: "15%",
                marginLeft: "10%",
                marginBottom: "10%",
                marginRight: "10%",
                whiteSpace: "pre-wrap", // Preserve whitespace and line breaks
                fontFamily: "monospace", // Use a monospaced font for a coding feel
                height: "100%", // Set the height of the container
                lineHeight: "20px", // Set the line height for consistent spacing
            }}
        >
            {displayedText}
        </p>
    );
};

const App = () => {
    const { years, months } = calculateExperience();
    const displayDurationText = `${years > 0 ? `${years} Years` : ""}${years > 0 && months > 0 ? ", " : ""}${months > 0 ? `${months} Months` : ""}`;
    const experienceText = `
        Welcome! I'm a dynamic Software Development Engineer with ${displayDurationText} in tech. I take charge of projects that elevate user engagement, optimize system performance, and streamline data processing. Explore my portfolio and join me in navigating the exciting intersections of technology and creativity. Let's make waves in the digital landscape together!`.replace(
        /\s+/g,
        " "
    );

    return (
        <>
            <TypingText text={experienceText} />
        </>
    );
};

export default App;

/**
 * Welcome to my digital space! I'm a dynamic and
                        result-driven Software Development Engineer with a rich
                        background of over 3 years in the tech industry. My
                        journey has been defined by a passion for leading
                        development projects that not only elevate user
                        engagement but also optimize system performance and
                        streamline data processing. Throughout my career, I've
                        honed my skills in transforming technical challenges
                        into tangible business results. My track record speaks
                        to a consistent ability to achieve significant
                        reductions in processing times, boost system efficiency,
                        and enhance overall user satisfaction. I thrive on
                        applying innovative solutions and leveraging
                        cutting-edge technologies to drive forward initiatives
                        that make a real impact. Join me on this journey as we
                        explore the intersections of technology and creativity.
                        Let's delve into the projects that have shaped my
                        professional narrative and discover how I bring a unique
                        blend of technical expertise and strategic thinking to
                        the table. Whether you're here to explore my portfolio,
                        learn more about my professional endeavors, or
                        collaborate on exciting ventures, I'm thrilled to have
                        you on board. Let's navigate the digital landscape
                        together!
 */
