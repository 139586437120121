import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { icons } from "../../utils/icons.js";

const GitHubRepos = ({ username, maxPages, hideForks }) => {
    const [repos, setRepos] = useState([]);
    const [filter, setFilter] = useState("");
    const [profile, setProfile] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 9;

    useEffect(() => {
        const getProfile = async () => {
            const res = await fetch(`https://api.github.com/users/${username}`);
            setProfile(await res.json());
        };

        const getReposObj = async () => {
            let allRepos = [];
            let res;
            let data = {};

            res = await fetch(
                "https://raw.githubusercontent.com/SatyamAnand98/data/4127cd53edca5eeac24828f6b82c0bf87d31df8b/github.json"
            );
            data = await res.json();
            data = data ? data.filter((repo) => !repo.fork) : {};
            allRepos = allRepos.concat(data);
            return allRepos;
        };

        const getRepos = async () => {
            let allRepos = [];
            let res;
            let data = {};
            for (let i = 1; i <= maxPages; i++) {
                res = await fetch(
                    `https://api.github.com/users/${username}/repos?&sort=pushed&per_page=100&page=${i}&type=${
                        hideForks ? "owner" : "all"
                    }`,
                    {
                        Accept: "application/vnd.github.v3+json",
                    }
                );
                data = await res.json();
                // data.message = "API rate limit exceeded";
                if (data.message) {
                    break;
                } else {
                    data = data ? data.filter((repo) => !repo.fork) : {};
                    allRepos = allRepos.concat(data);
                }
            }
            if (data.message) {
                allRepos = await getReposObj();
            }

            allRepos.sort((a, b) => b.forks_count - a.forks_count);
            allRepos.sort((a, b) => b.stargazers_count - a.stargazers_count);
            setRepos(allRepos);
        };
        getProfile();
        getRepos();
    }, [username, maxPages, hideForks]);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        setCurrentPage(1);
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    if (!profile)
        return (
            <GitHubReposStyled>
                <div>
                    <h1>GitHub API Rate Limit Exceeded</h1>
                    <p>
                        <strong>Please try again after sometime.</strong>
                    </p>
                    <p>
                        If you are the owner of this website, please visit{" "}
                        <a href="" target="_blank" rel="noopener noreferrer">
                            this link
                        </a>{" "}
                        for more information.
                    </p>
                </div>
            </GitHubReposStyled>
        );
    return (
        <GitHubReposStyled>
            <div className="user-info">
                <figure>
                    <img alt="user avatar" src={profile.avatar_url} />
                </figure>
                <div className="user-text">
                    <h2>
                        <a
                            href={profile.html_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: "none",
                                color: "inherit",
                            }}
                        >
                            <strong>{profile.name}</strong>
                        </a>
                    </h2>
                    <p>{profile.bio}</p>
                    <p>
                        <strong>@{profile.login} </strong>
                        <br />
                        <strong>Location:</strong> {profile.location}
                        <br />
                        Repos: {repos.length}
                        &nbsp; Followers: {profile.followers}
                        &nbsp; Following: {profile.following}
                        &nbsp; ForkedRepos:{" "}
                        {profile.public_repos - repos.length}
                    </p>
                </div>
            </div>

            <input
                type="text"
                className="filter-repos"
                value={filter}
                onChange={handleFilterChange}
                placeholder="Filter Repositories"
            />

            <ul className="repo-list">
                {repos
                    .filter((repo) =>
                        repo.name.toLowerCase().includes(filter.toLowerCase())
                    )
                    .slice(
                        (currentPage - 1) * itemsPerPage,
                        currentPage * itemsPerPage
                    )
                    .map((repo) => (
                        <li key={repo.id} className="repo">
                            <h3
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "pre-wrap",
                                    marginBottom: "10px",
                                    container: "inherit",
                                }}
                            >
                                {repo.name}
                            </h3>
                            <p>
                                <span
                                    style={{
                                        overflow: "hidden",
                                    }}
                                >
                                    {repo.description}
                                </span>
                                <br />
                                <br />

                                <a
                                    href={`${repo.html_url}/stargazers`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span>⭐ {repo.stargazers_count}</span>
                                </a>

                                {repo.language && (
                                    <span>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={`https://github.com/${username}?tab=repositories&q=&language=${repo.language}`}
                                        >
                                            {icons[repo.language]}{" "}
                                            <span>{repo.language}</span>
                                        </a>
                                    </span>
                                )}

                                {repo.forks_count > 0 && (
                                    <span>
                                        <a
                                            href={`${repo.html_url}/network/members`}
                                        >
                                            <span>{repo.forks_count}</span>
                                        </a>
                                    </span>
                                )}

                                {repo.homepage && repo.homepage !== "" ? (
                                    <>
                                        <br />
                                        <br />
                                        <a
                                            className="link-btn"
                                            href={repo.html_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Code
                                        </a>
                                        <a
                                            className="link-btn"
                                            href={repo.homepage}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Live
                                        </a>
                                        <br />
                                    </>
                                ) : (
                                    <>
                                        <br />
                                        <br />
                                        <a
                                            className="link-btn"
                                            href={repo.html_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            View Project
                                        </a>
                                        <br />
                                    </>
                                )}
                            </p>
                        </li>
                    ))}
            </ul>

            {/* Pagination */}
            <Pagination>
                {Array.from({
                    length: Math.ceil(repos.length / itemsPerPage),
                }).map((_, index) => (
                    <li key={index}>
                        <button onClick={() => paginate(index + 1)}>
                            {index + 1}
                        </button>
                    </li>
                ))}
            </Pagination>
        </GitHubReposStyled>
    );
};

const GitHubReposStyled = styled.div`
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    textalign: center;

    .filter-repos {
        margin-bottom: 10px;
        padding: 8px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 4px;
    }

    .user-info {
        display: flex;
        margin-bottom: 20px;
        flex-direction: row;
        align-items: center;

        .user-text {
            flex: 1;
        }

        figure {
            margin-left: 20px;
            width: 10vh;
            height: 10vh;
            overflow: hidden;
            border-radius: 50%;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            img {
                width: 100%;
                height: 100%;
            }
        }

        h2 {
            margin-bottom: 5px;
            font-size: 24px;
        }

        p {
            margin-bottom: 5px;
            font-size: 14px;
        }
    }

    .repo-list {
        list-style: none;
        padding: 20px;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

        .repo {
            margin-bottom: 15px;
            padding: 15px;
            border-radius: 8px;
            border: 1px solid #ddd;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            margin: 0 0 15px 15px;

            // @media (max-width: 768px) {
            //     margin: 0 0 15px 15px;
            // }

            // @media (max-width: 480px) {
            //     margin: 0 0 15px 15px;
            // }

            h3 {
                margin-bottom: 5px;
                font-size: 18px;
            }

            span {
                font-size: 14px;
            }

            a {
                color: #0366d6;
                text-decoration: none;
                margin-right: 10px;

                &:hover {
                    text-decoration: underline;
                }
            }

            .link-btn {
                display: inline-block;
                margin-top: 10px;
                padding: 8px 12px;
                background-color: #0366d6;
                color: #fff;
                text-decoration: none;
                border-radius: 4px;
                cursor: pointer;

                &:hover {
                    background-color: #0056b3;
                }
            }
        }
    }
`;

const Pagination = styled.ul`
    display: flex;
    list-style: none;
    padding: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    li {
        margin-right: 5px;

        button {
            padding: 8px;
            background-color: #0366d6;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background-color: #0056b3;
            }
        }
    }
`;

export default GitHubRepos;
