import React from "react";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import styled from "styled-components";
import { Button } from "@mui/material";

function Resume() {
    const resumeUrl =
        "https://raw.githubusercontent.com/SatyamAnand98/data/master/Satyam%20Anand%20Resume.pdf";

    return (
        <ResumeStyled>
            <div className="pdf-container">
                <div className="downloadPdf">
                    <Button href={resumeUrl} variant="contained">
                        Download Resume
                    </Button>
                </div>
                {/* <a href={resumeUrl} target="_blank" rel="noreferrer"> */}
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <div>
                        <Viewer fileUrl={resumeUrl} />
                    </div>
                </Worker>
                {/* </a> */}
            </div>
        </ResumeStyled>
    );
}

const ResumeStyled = styled.div`
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    height: 100%;
    width: 100%;
    .pdf-container {
        height: 100%;
        width: 100%;

        .downloadPdf {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
        }
    }
`;
export default Resume;
