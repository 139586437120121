import React from "react";
import styled from "styled-components";

export function Blogs() {
    const BlogsData = [
        {
            category: "DNS",
            blogs: [
                {
                    title: "Decoding DNS: Unveiling the World of Record Types",
                    source: (
                        <iframe
                            src="https://www.linkedin.com/embed/feed/update/urn:li:share:7162090077014179840"
                            // height="1736"
                            // width="504"
                            frameborder="0"
                            allowfullscreen="false"
                            title="Embedded post"
                        ></iframe>
                    ),
                    linkedinUrl:
                        "https://www.linkedin.com/posts/satyamanand_internetmagic-techexploration-webadventure-activity-7162090077886558208-woZp",
                    mediumUrl:
                        "https://medium.com/@satyam-anand/demystifying-dns-navigating-the-intricacies-of-dns-record-types-and-the-web-journey-3852cbda964b",
                },
                {
                    title: "How Your Clicks Connect to Websites.",
                    source: (
                        <iframe
                            src="https://www.linkedin.com/embed/feed/update/urn:li:share:7161729325141094403"
                            // height="1736"
                            // width="504"
                            frameborder="0"
                            allowfullscreen="true"
                            title="Embedded post"
                        ></iframe>
                    ),
                    linkedinUrl:
                        "https://www.linkedin.com/posts/satyamanand_webjourney-dnsmagic-internetadventure-activity-7161729326218928128-9PU5",
                    mediumUrl:
                        "https://medium.com/@satyam-anand/demystifying-dns-navigating-the-intricacies-of-dns-record-types-and-the-web-journey-3852cbda964b",
                },
            ],
        },
        // {
        //     category: "System Design",
        //     blogs: [
        //         {
        //             title: "System Design: A Comprehensive Guide",
        //             source: (
        //                 <iframe
        //                     src="https://www.linkedin.com/embed/feed/update/urn:li:share:7048537028585512960"
        //                     frameborder="0"
        //                     allowfullscreen=""
        //                     title="Embedded post"
        //                 ></iframe>
        //             ),
        //             url: "https://www.linkedin.com/posts/bytebytego_ive-been-writing-the-system-design-newsletter-activity-7048537029407592449-jmmh",
        //         },
        //     ],
        // },
        {
            category: "Internet's Magic",
            blogs: [
                {
                    title: "Evolution of Communication: From Telegraphs to Satellite to Undersea Cables",
                    source: (
                        <iframe
                            src="https://www.linkedin.com/embed/feed/update/urn:li:share:7165316224321540096"
                            // height="1974"
                            // width="504"
                            frameborder="0"
                            allowfullscreen=""
                            title="Embedded post"
                        ></iframe>
                    ),
                    linkedinUrl:
                        "https://www.linkedin.com/posts/satyamanand_evolution-of-communication-from-telegraphs-activity-7165316226741731328-Xdqi?utm_source=share&utm_medium=member_desktop",
                },
            ],
        },
    ];

    const [category, setCategory] = React.useState("DNS");
    const [blogs, setBlogs] = React.useState(BlogsData[0].blogs);

    const handleCategory = (category) => {
        setCategory(category);
        BlogsData.map((data) => {
            if (data.category === category) {
                setBlogs(data.blogs);
            }
        });
    };

    return (
        <BlogsStyled>
            <Categories>
                {BlogsData.map((data, index) => (
                    <BlogCategory
                        key={index}
                        onClick={() => handleCategory(data.category)}
                    >
                        {data.category}
                    </BlogCategory>
                ))}
            </Categories>
            <BlogContainer>
                {blogs.map((blog, index) => (
                    <BlogCard key={index}>
                        <h2>{blog.title}</h2>
                        <div>
                            {blog.linkedinUrl && (
                                <Button href={blog.linkedinUrl} target="_blank">
                                    Read On LinkedIn
                                </Button>
                            )}
                            {blog.mediumUrl && (
                                <Button href={blog.mediumUrl} target="_blank">
                                    Read On Medium
                                </Button>
                            )}
                        </div>
                        <Blog>{blog.source}</Blog>
                    </BlogCard>
                ))}
            </BlogContainer>
        </BlogsStyled>
    );
}

const BlogsStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
`;

const Categories = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
`;

const BlogContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    z-index: 1;
    @media (max-width: 768px) {
        padding: 1rem;
    }
    @media (max-width: 500px) {
        padding: 0.5rem;
    }
`;

const Blog = styled.div`
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: 1rem;
    iframe {
        width: 100%;
        height: 400px;
        border-radius: 1rem;
    }
    @media (max-width: 768px) {
        iframe {
            width: 100%;
            height: 300px;
        }
    }
    @media (max-width: 500px) {
        iframe {
            width: 100%;
            height: 400px;
        }
    }
`;

const BlogCard = styled.div`
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px 0 var(--color-black);
    width: 100%;
    max-width: 500px;
    height: 100%;
    margin: 1rem;
    transition: all 0.3s ease;
    h2 {
        color: var(--color-white);
        margin-bottom: 1rem;
    }
    @media (max-width: 768px) {
        padding: 1rem;
        width: 400px;
    }
    @media (max-width: 500px) {
        padding: 1rem;
        width: 300px;
    }

    &:hover {
        transform: scale(1.05);
    }
`;

const BlogCategory = styled.div`
    z-index: 1;
    text-align: center;
    padding: 1rem;
    margin: 1rem;
    border-radius: 1rem;
    background: var(--color-black);
    color: var(--color-white);
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
        background: var(--color-grey);
        color: var(--color-black);
    }
    &:active {
        transform: scale(0.95);
    }
`;

const Button = styled.a`
    z-index: 1;
    display: inline-flex;
    padding: 1rem;
    background: var(--color-black);
    color: var(--color-white);
    border-radius: 1rem;
    margin: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;

    @media (max-width: 768px) {
        width: calc(50% - 2rem);
        justify-content: center;
    }

    @media (max-width: 500px) {
        width: calc(50% - 2rem);
        justify-content: center;
    }

    &:hover {
        background: var(--color-grey);
        color: var(--color-black);
        scale: 1.1;
    }
    &:active {
        transform: scale(0.95);
    }
`;
