import styled from "styled-components";

export const MainLayout = styled.div`
    padding: 0.5vw;
    @media screen and (max-width: 1280px) {
        padding: 0.5vw;
    }
    @media screen and (max-width: 768px) {
        padding: 1vw;
    }
    @media screen and (max-width: 510px) {
        padding: 1vw;
    }
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;
