import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { navigation } from "../../utils/navigations.js";
import { useWindowSize } from "../../utils/useWindowSize.js";
import { icons } from "../../utils/icons.js";
import { Link, NavLink } from "react-router-dom";

function Menu({ active, setActive }) {
    const { width } = useWindowSize();
    const isSmallScreen = width <= 768;
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const menuRef = useRef(null);
    const location = window.location;

    const handleDropdownToggle = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleDocumentClick = (event) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target) &&
            dropdownVisible
        ) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);

        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, [dropdownVisible]);

    return (
        <>
            {isSmallScreen ? (
                <MenuStyled ref={menuRef}>
                    <div className="menu-container">
                        <div
                            className="menu-header"
                            onClick={handleDropdownToggle}
                            style={{
                                width: "fit-content",
                                cursor: "pointer",
                            }}
                        >
                            <TransitionIcon
                                dropdownvisible={
                                    dropdownVisible ? "true" : "false"
                                }
                            >
                                {dropdownVisible
                                    ? icons.cross
                                    : icons.hamburger}
                            </TransitionIcon>
                        </div>
                        {dropdownVisible && (
                            <ul className="menu-items" id="menu-dropdown">
                                {navigation.map((item) => (
                                    <li
                                        key={item.id}
                                        onClick={() => {
                                            setActive(item.name);
                                            handleDropdownToggle();
                                        }}
                                        className={
                                            active === item.name
                                                ? "fa-solid"
                                                : ""
                                        }
                                    >
                                        <Link
                                            to={item.path}
                                            style={{
                                                textDecoration: "none",
                                                color: "black",
                                            }}
                                        >
                                            <span>
                                                {item.icons} {item.name}
                                            </span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </MenuStyled>
            ) : (
                <MenuStyled>
                    <ul className="menu-items-row">
                        {navigation.map((item) => {
                            return (
                                <li
                                    key={item.id}
                                    onClick={() => setActive(item.name)}
                                    className={
                                        active === item.name ? "active" : ""
                                    }
                                >
                                    <NavLink
                                        to={item.path}
                                        style={{
                                            textDecoration: "none",
                                            color: "black",
                                        }}
                                        activeClassName="active"
                                    >
                                        <span>
                                            {item.icons} {item.name}
                                        </span>
                                    </NavLink>
                                </li>
                            );
                        })}
                    </ul>
                </MenuStyled>
            )}
        </>
    );
}

const MenuStyled = styled.nav`
    @media (min-width: 768px) {
        z-index: 0;
        position: absolute;
    }
    position: flex;

    .menu-items-row {
        display: flex;
        gap: 1rem;
        list-style: none;
        padding: 0.5rem;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        z-index: 1;

        li {
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
                transform: scale(1.2);
            }
            font-weight: 100;
            &.active {
                font-weight: bold;
                font-size: 1.2rem;
                padding: 0.5rem;
                border-radius: 5px;
                background-color: var(--color-grey);
                color: var(--color-black);
            }
        }
    }

    .menu-container {
        position: absolute;
        width: 100%;
    }

    .menu-header {
        cursor: pointer;
        padding: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;

        span {
            display: flex;
            align-items: center;
        }
    }

    .menu-items {
        position: absolute;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-weight: 100;
        z-index: 3;
        margin-left: -10px;
        background-color: var(--color-light);
        border-radius: 5px;
        
        li {
            cursor: pointer;
            background-color: var(--color-black);
            cursor: "pointer",
            padding: 0.5rem;
            border-radius: 5px;

            &.active {
                font-weight: 500;
                font-size: 1.5rem;
                span {
                    background-color: var(--color-grey);
                    color: var(--color-black);
                    border-radius: 5px;
                }
            }

            span {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                padding: 0.5rem;

                &:hover {
                    background-color: var(--color-white);
                    color: var(--color-black);
                    border-radius: 5px;
                    border: 1px solid var(--color-black);
                }
            }

            transition: all 0.3s ease-in-out;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
`;

const TransitionIcon = styled.span`
    display: flex;
    align-items: center;
    transition: transform 0.3s ease-in-out; // Specify the transition properties

    ${(props) =>
        props.dropdownvisible === "true" &&
        `
        transform: rotate(90deg);
    `}
`;

export default Menu;
