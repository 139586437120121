import { navItems } from "../store/navItems.js";
import { icons } from "../utils/icons.js";

export const navigation = [
    {
        id: 1,
        name: navItems.Home,
        path: "/",
        icons: icons.home,
    },
    {
        id: 2,
        name: navItems.About,
        path: "/about",
        icons: icons.about,
    },
    {
        id: 3,
        name: navItems.Projects,
        path: "/projects",
        icons: icons.projects,
    },
    // {
    //     id: 4,
    //     name: navItems.Contact,
    //     path: "/contact",
    //     icons: icons.contact,
    // },
    {
        id: 5,
        name: navItems.Resume,
        path: "/resume",
        icons: icons.resume,
    },
    // {
    //     id: 6,
    //     name: navItems.Social,
    //     path: "/social",
    //     icons: icons.social,
    // },
    {
        id: 7,
        name: navItems.Blog,
        path: "/blog",
        icons: icons.blog,
    },
    {
        id: 8,
        name: navItems.Journey,
        path: "/journey",
        icons: icons.journey,
    },
];
