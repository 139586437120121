export const navItems = {
    Home: "Home",
    About: "About",
    Projects: "Projects",
    Contact: "Contact",
    Resume: "Resume",
    Social: "Social",
    Blog: "Blogs",
    Journey: "Journey",
};
