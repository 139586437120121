import React from "react";
import styled from "styled-components";

function About() {
    return (
        <AboutStyled>
            <div className="technologies">
                <br />
                <h3>Languages I've worked with</h3>
                <img src="https://img.shields.io/badge/python%20-%2314354C.svg?&style=for-the-badge&logo=python&logoColor=white" />{" "}
                <img src="https://img.shields.io/badge/javascript%20-%23323330.svg?&style=for-the-badge&logo=javascript&logoColor=%23F7DF1E" />{" "}
                <img src="https://img.shields.io/badge/TypeScript-%23007ACC.svg?&style=for-the-badge&logo=typescript&logoColor=white" />{" "}
                {/* <img src="https://img.shields.io/badge/Java-ED8B00?style=for-the-badge&logo=openjdk&logoColor=white" />{" "} */}
                {/* <img src="https://img.shields.io/badge/c%20-%2300599C.svg?&style=for-the-badge&logo=c&logoColor=white" />{" "} */}
                {/* <img src="https://img.shields.io/badge/c++%20-%2300599C.svg?&style=for-the-badge&logo=c%2B%2B&ogoColor=white" />{" "} */}
                {/* <img src="https://img.shields.io/badge/Rust-%23000000.svg?&style=for-the-badge&logo=rust&logoColor=white" />{" "} */}
                {/* <img src="https://img.shields.io/badge/Go-%2300ADD8.svg?&style=for-the-badge&logo=go&logoColor=white" />{" "} */}
                {/* <img src="https://img.shields.io/badge/Scala-%23DC322F.svg?&style=for-the-badge&logo=scala&logoColor=white" />{" "} */}
                {/* <img src="https://img.shields.io/badge/Perl-%2339457E.svg?&style=for-the-badge&logo=perl&logoColor=white" />{" "} */}
                {/* <img src="https://img.shields.io/badge/R-%23276DC3.svg?&style=for-the-badge&logo=r&logoColor=white" />{" "} */}
                <img src="https://img.shields.io/badge/GraphQL-E10098?style=for-the-badge&logo=graphql&logoColor=white" />{" "}
                <br />
                <br />
                <h3>Libraries</h3>
                <img
                    src="https://img.shields.io/badge/Node.js-339933?logo=node.js&logoColor=white&style=for-the-badge"
                    alt="NodeJs"
                />{" "}
                <img src="https://img.shields.io/badge/React-20232A?logo=react&logoColor=61DAFB&style=for-the-badge" />{" "}
                <br />
                <br />
                <h3>Frameworks</h3>
                <img src="https://img.shields.io/badge/Express.js-404D59?logo=express&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/Flask-000000?logo=flask&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/FastAPI-009639?logo=fastapi&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/Django-092E20?logo=django&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/Angular-DD0031?logo=angular&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/NestJS-E0234E?logo=nestjs&logoColor=white&style=for-the-badge" />{" "}
                <br />
                <br />
                <h3>Database</h3>
                <img src="https://img.shields.io/badge/MySQL-00000F?logo=mysql&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/PostgreSQL-316192?logo=postgresql&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/MongoDB-4EA94B?logo=mongodb&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/Redis-DC382D?logo=redis&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/Elasticsearch-005571?logo=elastic&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/qldb-232F3E?logo=amazon-aws&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/snowflake-232F3E?logo=snowflake&logoColor=white&style=for-the-badge" />{" "}
                <br />
                <br />
                <h3>Tools</h3>
                <img src="https://img.shields.io/badge/Git-F05032?logo=git&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/Jira-0052CC?logo=jira&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/Confluence-172B4D?logo=confluence&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/Slack-4A154B?logo=slack&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/Notion-000000?logo=notion&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/Postman-FF6C37?logo=postman&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/Elastic%20Kibana-005571?logo=elastic&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/Sentry-362D59?logo=sentry&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/kafka-000000?logo=apache-kafka&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/nginx-009639?logo=nginx&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/ngrok-1F1E37?logo=ngrok&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/Kibana-005571?logo=elastic&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/apache-1F1E37?logo=apache&logoColor=white&style=for-the-badge" />{" "}
                <br />
                <br />
                <h3>Cloud</h3>
                <img src="https://img.shields.io/badge/AWS-232F3E?logo=amazon-aws&logoColor=white&style=for-the-badge" />{" "}
                {/* <img src="https://img.shields.io/badge/Azure-232F3E?logo=microsoft-azure&logoColor=white&style=for-the-badge" />{" "} */}
                <img src="https://img.shields.io/badge/Heroku-430098?logo=heroku&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/DigitalOcean-167FFB?logo=digitalocean&logoColor=white&style=for-the-badge" />{" "}
                {/* <img src="https://img.shields.io/badge/Google%20Cloud-4285F4?logo=google-cloud&logoColor=white&style=for-the-badge" />{" "} */}
                {/* <img src="https://img.shields.io/badge/Netlify-00C7B7?logo=netlify&logoColor=white&style=for-the-badge" />{" "} */}
                {/* <img src="https://img.shields.io/badge/Vercel-000000?logo=vercel&logoColor=white&style=for-the-badge" />{" "} */}
                {/* <img src="https://img.shields.io/badge/OnRender-000000?logo=onrender&logoColor=white&style=for-the-badge" />{" "} */}
                <br />
                <br />
                <h3>CI/CD</h3>
                {/* <img src="https://img.shields.io/badge/GitHub%20Actions-2088FF?logo=github-actions&logoColor=white&style=for-the-badge" />{" "} */}
                {/* <img src="https://img.shields.io/badge/Bitbucket%20Pipelines-0052CC?logo=bitbucket&logoColor=white&style=for-the-badge" />{" "} */}
                <img src="https://img.shields.io/badge/Kubernetes-326CE5?logo=kubernetes&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/miniKube-326CE5?logo=kubernetes&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/Docker-2CA5E0?logo=docker&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/Helm-2CA5E0?logo=helm&logoColor=white&style=for-the-badge" />{" "}
                {/* <img src="https://img.shields.io/badge/Logstash-005571?logo=elastic&logoColor=white&style=for-the-badge" />{" "} */}
                {/* <img src="https://img.shields.io/badge/LogDNA-005571?logo=logdna&logoColor=white&style=for-the-badge" />{" "} */}
                {/* <img src="https://img.shields.io/badge/containerd-1F1E37?logo=containerd&logoColor=white&style=for-the-badge" />{" "} */}
                <br />
                <br />
                <h3>Communication Protocols</h3>
                <img src="https://img.shields.io/badge/HTTP-005571?logo=http&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/HTTPS-005571?logo=https&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/REST-005571?logo=rest&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/GraphQL-005571?logo=graphql&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/WebSockets-005571?logo=websocket&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/rpc-005571?logo=rpc&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/grpc-005571?logo=grpc&logoColor=white&style=for-the-badge" />{" "}
                <img src="https://img.shields.io/badge/MQTT-005571?logo=mqtt&logoColor=white&style=for-the-badge" />{" "}
                <br />
                <br />
                <h3>Operating Systems</h3>
                <img src="https://img.shields.io/badge/OS-Linux-informational?style=flat&logo=linux&logoColor=white&color=2bbc8a" />{" "}
                <img src="https://img.shields.io/badge/OS-Windows-informational?style=flat&logo=windows&logoColor=white&color=2bbc8a" />{" "}
                <img src="https://img.shields.io/badge/OS-MacOS-informational?style=flat&logo=apple&logoColor=white&color=2bbc8a" />{" "}
                <img src="https://img.shields.io/badge/OS-Linux%20Mint-informational?style=flat&logo=linux-mint&logoColor=white&color=2bbc8a" />{" "}
                <br />
                <br />
            </div>
        </AboutStyled>
    );
}

const AboutStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #282c34;
    color: white;
    height: 100vh;
    width: 100vw;
    overflow: auto;
    padding: 0 2rem;
    box-sizing: border-box;
    font-family: "Roboto Mono", monospace;
    font-size: 1.5rem;
    text-align: center;
    line-height: 1.5;
    letter-spacing: 0.05rem;
    font-weight: 400;
    text-shadow: 0 0 0.5rem #282c34;
    position: inherit;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-origin: border-box;
    background-clip: border-box;
    background-color: transparent;
    textalign: center;
`;
export default About;
